<template>
    <div>
        <mdb-row>
            <mdb-col md="4">
                <datepicker label="訂單日期" v-model="orders.day" :max="new Date()" v-if="type != 'view'" />
                <mdb-input label="訂單日期" disabled :value="orders.day" v-show="type == 'view'"></mdb-input>
            </mdb-col>
        </mdb-row>
        <mdb-card class="mb-2">
            <mdb-card-header style="color:#fff">客戶資料</mdb-card-header>
            <mdb-card-body>
                <mdb-row>
                    <mdb-col md="4">
                        <mdb-input label="姓名" v-model="orders.name"> </mdb-input>
                    </mdb-col>
                    <mdb-col md="4">
                        <mdb-input label="電話" v-model="orders.tel"> </mdb-input>
                    </mdb-col>
                </mdb-row>
            </mdb-card-body>
        </mdb-card>
        <mdb-card class="mb-2">
            <mdb-card-header style="color:#fff">訂單項目</mdb-card-header>
            <mdb-card-body>
                <div class="rwd-table-dispersion">
                    <table class="table table-striped table-hover" style="width:100%">
                        <thead>
                            <tr>
                                <th style="width:3rem">#</th>
                                <th>商品名稱</th>
                                <th style="width:10rem">金額</th>
                                <th style="width:5rem">數量</th>
                                <th style="width:5rem"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(oi, oik) in orders_itemList" :key="`oi_${oik}`">
                                <td data-title="#">{{ oik + 1 }}</td>
                                <td data-title="商品名稱" :style="oi.remove == '1' ? 'text-decoration: line-through' : ''">
                                    <div v-if="k.productList[oi.id_product]">
                                        {{ k.productList[oi.id_product].name }}<br />
                                        <small style="color:#999">
                                            {{ k.productList[oi.id_product].code }}
                                        </small>
                                    </div>
                                    <div v-else>
                                        {{ oi.product_name }}<br />
                                        <small>{{ oi.product_code }}</small>
                                    </div>
                                </td>
                                <td data-title="金額" :style="oi.remove == '1' ? 'text-decoration: line-through' : ''">
                                    <mdb-input
                                        class="m-0 price-input"
                                        type="number"
                                        v-model.number="oi.price"
                                        v-show="type != 'view' && oi.remove == '0'"
                                    />
                                    {{ type == 'view' || oi.remove == '1' ? $numeral(oi.price).format('0,0') : '' }}
                                </td>
                                <td data-title="數量" :style="oi.remove == '1' ? 'text-decoration: line-through' : ''">
                                    <mdb-input
                                        class="m-0 price-input"
                                        type="number"
                                        :min="1"
                                        v-model.number="oi.amount"
                                        v-show="type == 'add' || orders.status != '0'"
                                    />
                                    {{ type != 'add' && orders.status == '0' ? oi.amount : '' }}
                                </td>
                                <td>
                                    <mdb-btn
                                        color="danger"
                                        size="sm"
                                        @click="del_order_item(oi)"
                                        v-show="type != 'view' && oi.remove == 0"
                                    >
                                        <i class="fa fa-trash"></i>
                                    </mdb-btn>
                                </td>
                            </tr>
                            <tr v-show="orders_itemList.length == 0">
                                <td colspan="6">無資料</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <p class="font-small grey-text mb-0">選取並新增項目</p>
                <SearchSelect
                    placeholder="=== 請輸入關鍵字 ==="
                    :options="product_select"
                    v-model="add_product_id"
                ></SearchSelect>
            </mdb-card-body>
        </mdb-card>
        <p class="text-right pr-2" v-show="get_total_price > 0">總金額：{{ $numeral(get_total_price).format('0,0') }}</p>
        <mdb-card class="mb-2">
            <mdb-card-body>
                <mdb-input type="textarea" label="備註" v-model="orders.remark" :disabled="type == 'view'"></mdb-input>
            </mdb-card-body>
        </mdb-card>
        <div class="text-right mb-4" v-show="type != 'view'">
            <mdb-btn color="info" @click="save_orders('keep')" v-if="orders.status != '0'">
                <i class="fa fa-check"></i>
                &nbsp;暫存
            </mdb-btn>
            <mdb-btn color="default" @click="save_orders('send')">
                <i class="fa fa-save"></i>
                &nbsp;儲存
            </mdb-btn>
        </div>
    </div>
</template>
<script>
    import {
        mdbCard,
        mdbCardHeader,
        // mdbCardTitle,
        mdbCardBody,
        mdbRow,
        mdbCol,
        mdbInput,
        mdbBtn,
        // mdbSelect,
        // mdbModal,
        // mdbModalHeader,
        // mdbModalBody,
        // mdbModalFooter,
        // mdbBadge,
    } from 'mdbvue';
    import datepicker from '../../components/datepicker';
    import SearchSelect from '../../components/SearchSelect';
    export default {
        components: {
            mdbCard,
            mdbCardHeader,
            // mdbCardTitle,
            mdbCardBody,
            mdbRow,
            mdbCol,
            mdbInput,
            mdbBtn,
            // mdbSelect,
            datepicker,
            SearchSelect,
            // mdbModal,
            // mdbModalHeader,
            // mdbModalBody,
            // mdbModalFooter,
            // mdbBadge,
        },
        data() {
            return {
                orders: {},
                orders_itemList: [],
                orders_itemEmpty: {},
                product_classList: [],
                productList: [],
                add_product_id: '',
                // 動態產生臨時的orders_item.id用
                orders_item_count: 0,
            };
        },
        watch: {
            '$route.path': {
                handler() {
                    if (this.$route.path == '/orders/add/') {
                        location.reload();
                    }
                },
                deep: true,
            },
            add_product_id() {
                let vue = this;
                if (vue.add_product_id != '') {
                    // 檢查是不是已經選過了
                    let index = vue.orders_itemList.findIndex((oi) => {
                        return oi.id_product == vue.add_product_id;
                    });
                    if (index == -1) {
                        // 把商品資料加入訂單項目裡面
                        vue.orders_itemList.push(
                            Object.assign({}, vue.orders_itemEmpty, {
                                id: 'add_' + vue.orders_item_count,
                                id_product: vue.add_product_id,
                                price: vue.k.productList[vue.add_product_id].price,
                                cost: vue.k.productList[vue.add_product_id].cost,
                                product_name: vue.k.productList[vue.add_product_id].name,
                                product_code: vue.k.productList[vue.add_product_id].code,
                            })
                        );
                        vue.add_product_id = '';
                        vue.orders_item_count++;
                    }
                }
            },
        },
        mounted() {
            this.get_data();
        },
        computed: {
            k() {
                let output = { productList: {}, product_classList: {} };
                for (let k in output) {
                    this[k].forEach((item) => {
                        output[k][item.id] = item;
                    });
                }
                return output;
            },
            type() {
                if (this.$route.params.edit && this.$route.params.id) {
                    return 'edit';
                } else if (this.$route.params.id) {
                    return 'view';
                } else {
                    return 'add';
                }
            },
            product_select() {
                let output = [];
                this.productList.forEach((item) => {
                    output.push({
                        key: item.id,
                        value: `【${item.code}】- ${item.name}`,
                    });
                });
                return output;
            },
            // 計算總價
            get_total_price() {
                let output = 0;
                this.orders_itemList.forEach((item) => {
                    if (item.remove == '0') {
                        output += this.$math.multiply(item.price, item.amount);
                    }
                });
                return output;
            },
        },
        methods: {
            /**
             * 取出基本資料
             */
            get_data() {
                let vue = this,
                    query = [
                        {
                            name: 'product_class',
                            data: {},
                            sort: {
                                name: 0,
                            },
                        },
                        {
                            name: 'product',
                            data: {},
                            sort: {
                                name: 0,
                            },
                        },
                    ];
                if (this.type == 'add') {
                    query = query.concat([
                        {
                            name: 'orders',
                        },
                        {
                            name: 'orders_item',
                        },
                    ]);
                } else {
                    query = query.concat([
                        {
                            name: 'orders',
                            data: {
                                id: {
                                    type: 0,
                                    value: this.$route.params.id,
                                },
                            },
                        },
                        {
                            name: 'orders_item',
                            data: {
                                id_orders: {
                                    type: 0,
                                    value: vue.$route.params.id,
                                },
                            },
                        },
                    ]);
                }

                vue.$store
                    .dispatch('get_form', {
                        payload: {
                            url: `data/get_sql_data/?query=${JSON.stringify(query)}`,
                        },
                    })
                    .then((res) => {
                        if (res.data.status == 'ok') {
                            if (vue.type == 'add') {
                                vue.orders = {};
                                for (let k in res.data.ordersEmpty) {
                                    vue.orders[k] = '';
                                }
                                vue.orders_itemEmpty = res.data.orders_itemEmpty;

                                vue.orders.id_admin = vue.$store.state.user.id;
                                vue.orders.day = vue.$moment().format('YYYY-MM-DD');
                            } else {
                                vue.orders = res.data.ordersList[0];
                                res.data.orders_itemList.map((item) => {
                                    return item;
                                });
                                vue.orders_itemList = res.data.orders_itemList;
                                vue.orders_item_count = res.data.orders_itemList.length;
                            }
                            vue.product_classList = res.data.product_classList;
                            vue.productList = res.data.productList;
                            // 訂單項目預設值
                            vue.orders_itemEmpty = res.data.orders_itemEmpty;
                            vue.orders_itemEmpty.amount = 1;
                            vue.orders_itemEmpty.price = 0;
                            vue.orders_itemEmpty.remove = 0;
                        }
                    });
            },
            // 刪除銷售項目
            del_order_item(oi) {
                let vue = this;
                if (vue.type == 'add') {
                    vue.$set(oi, 'del', true);
                    vue.orders_itemList = vue.orders_itemList.filter((item) => {
                        return !item.del;
                    });
                }
            },
            /**
             * 儲存訂單
             * @param String type 儲存類型
             */
            save_orders(type) {
                let vue = this,
                    msg = '';
                if (vue.orders_itemList.length == 0) {
                    msg = '請至少新增一個品項';
                }
                if (msg == '') {
                    // 送出訂單資料
                    if (type == 'send') {
                        vue.$swal.fire({
                            title: '確定要儲存並送出嗎?',
                            showCancelButton: true,
                            icon: 'info',
                            showLoaderOnConfirm: true,
                            allowOutsideClick: false,
                            preConfirm() {
                                vue.orders.status = '0';
                                return vue.save_order_process(type, () => {
                                    vue.$swal
                                        .fire({
                                            icon: 'success',
                                            title: '儲存完成!',
                                            timer: 1500,
                                            showConfirmButton: false,
                                            allowOutsideClick: false,
                                        })
                                        .then(() => {
                                            if (vue.$route.fullPath != '/orders/add/') {
                                                vue.$router.push('/orders/add/');
                                            } else {
                                                location.reload();
                                            }
                                        });
                                });
                            },
                        });
                    } else {
                        // 暫存訂單
                        vue.orders.status = '1';
                        vue.$swal.fire({
                            title: '儲存中',
                            html: '<i class="fa fa-sync fa-spin fa-4x"></i>',
                            showConfirmButton: false,
                            didOpen() {
                                vue.save_order_process(type, (res) => {
                                    vue.orders.id = res.data.orders_id;
                                    vue.$router.push(`/orders/edit/${res.data.orders_id}/Y`);
                                    vue.orders_itemList.map((oi) => {
                                        oi.id = res.data.orders_item_ids[oi.id] ? res.data.orders_item_ids[oi.id] : oi.id;
                                        return oi;
                                    });
                                });
                                vue.$swal.fire({
                                    icon: 'success',
                                    title: '儲存完成!',
                                    timer: 1500,
                                    toast: true,
                                    position: 'top-right',
                                    showConfirmButton: false,
                                    allowOutsideClick: false,
                                });
                            },
                        });
                    }
                } else {
                    vue.$swal.fire({
                        icon: 'error',
                        title: msg,
                        timer: 1500,
                        showConfirmButton: false,
                        toast: true,
                        // position: "bottom-right",
                    });
                }
            },
            save_order_process(type, callback) {
                let vue = this;
                vue.$store
                    .dispatch('post_form', {
                        payload: {
                            url: 'orders/save_orders',
                            data: {
                                data: JSON.stringify({
                                    orders: vue.orders,
                                    orders_item: vue.orders_itemList,
                                }),
                                save_type: type,
                            },
                        },
                    })
                    .then((res) => {
                        if (res.data.status == 'ok') {
                            if (typeof callback == 'function') {
                                callback(res);
                            }
                        }
                    });
            },
        },
    };
</script>
<style lang="scss" slot-scope>
    .remark_text.md-form textarea.md-textarea {
        padding: 0;
    }
</style>
